import React, { Fragment, useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Container, Row, Col } from 'react-bootstrap'
import { FaSearch } from "react-icons/fa";
import { useStaticQuery, graphql } from "gatsby"
import Logo from '../../../../components/logo'
import Text from '../../../../components/ui/text'
import Anchor from '../../../../components/ui/anchor'
import BurgerButton from '../../../../components/ui/burger-button'
import OffCanvas, { OffCanvasHeader, OffCanvasBody } from '../../../../components/ui/off-canvas';
import Clickable from '../../../../components/ui/clickable'
import { MainMenu, MobileMenu } from '../../../../components/menu'
import Language from '../../../../components/language'
import HeaderForm from '../../../../components/forms/search-form/layout-one'
import {
    HeaderWrap,
    HeaderTop,
    HeaderBottom,
    FixedHeader,
    HeaderMain,
    HeaderLeft,
    HeaderMiddle,
    HeaderRight,
    HeaderRightInner,
    HeaderNavigation,
    HeaderElement,
    FixedHeaderHeight
} from './header.style'

const Header = (props) => {
    const menuData = useStaticQuery(graphql`
        query MenuOneQuery {
            allMenuJson {
                edges {
                    node {
                        id
                        text
                        link                        
                        isDynamicMenu                        
                    }
                }
            }
            cities: allAirtable(
                filter: {
                  table: { eq: "Cities" }
                }
              ) {
                edges {
                  node {
                    data {                      
                      Name
                      Slug                      
                      WorldRegion
                    }
                  }
                }
            }
        }
    `)



    const _menu = [
        {
            "text": "Journeys",
            "link": "/trips"
        },
        {
            "text": "Locations",
            "link": "/cities"
            
        },
        {
            "text": "Points & Miles",
            "link": "/category/points-miles"
        },
        {
            "text": "Travel Hacks",
            "link": "/category/travel-hacks"
        },
        {
            "text": "Explore",
            "link": "/explore",
        }
    ]

    const menuArr = menuData.allMenuJson.edges;
    const cities = menuData.cities.edges;
    const [offCanvasOpen, setOffcanvasOpen] = useState(false);
    const [headerInnerOpen, setHeaderInnerOpen] = useState(false);
    const [fixedHeaderHeight, setFixedHeaderHeight] = useState(0);
    const [totalHeaderHeight, setTotalHeaderHeight] = useState(0);
    const [sticky, setSticky] = useState(false);
    const headerRef = useRef(null);
    const fixedRef = useRef(null);



    let worldRegionArr = []; 

    cities.map(city => {
        worldRegionArr.push(city.node.data.WorldRegion)
    })


    const _uniqueCities = new Set(worldRegionArr);

    const uniqueCities = [..._uniqueCities];

    const cityMenu = {
        cities: cities,
        cityHeaders : uniqueCities
    }

    const offCanvasHandler = () => {
        setOffcanvasOpen(prevState => !prevState);
    }

    const headerInnerHandler = () => {
        setHeaderInnerOpen(prevState => !prevState);
    }

    useEffect(() => {
        setFixedHeaderHeight(fixedRef.current.clientHeight);
        setTotalHeaderHeight(headerRef.current.clientHeight);
    }, []);

    useEffect(() => {
        const scrollHandler = () => {
            let scrollPos = window.scrollY;
            if (scrollPos > totalHeaderHeight) {
                setSticky(true)
            }

            if (scrollPos < fixedHeaderHeight) {
                setSticky(false)
            }
        }

        window.addEventListener('scroll', scrollHandler);
        return () => {
            window.removeEventListener('scroll', scrollHandler);
        }
    }, [sticky, fixedHeaderHeight, totalHeaderHeight]);

    const { headerTop, headerBottom } = props;
    const { textStyle, linkStyle } = headerTop;
    const { logoStyle, langElStyle, burgerBtnElStyle, clickAbleElStyle, clickAble, headerFormElStyle } = headerBottom;

    return (
        <Fragment>
            <HeaderWrap ref={headerRef}>
                <HeaderTop>
                    <Container fluid>
                        <Row>
                            <Col lg={12}>
                                <Anchor path="/trips" {...linkStyle}>
                                    <Text className="popper" {...textStyle}>Journeys: Tired of planning? Check out our pre-planned trips you can simply purchase.</Text>
                                </Anchor>
                            </Col>
                        </Row>
                    </Container>
                </HeaderTop>
                <HeaderBottom>
                    <FixedHeader ref={fixedRef} isSticky={sticky}>
                        <Container fluid className="header-container">
                            <Row>
                                <Col lg={12}>
                                    <HeaderMain>
                                        <HeaderLeft>
                                            <Logo className="logo" darkLogo {...logoStyle} />
                                        </HeaderLeft>
                                        <HeaderMiddle>
                                            <HeaderNavigation>
                                                <MainMenu menuData={menuArr} cityMenu={cityMenu} newMenu={_menu} />
                                            </HeaderNavigation>
                                        </HeaderMiddle>
                                        <HeaderRight>
                                        <HeaderRightInner isOpen={headerInnerOpen}>                                                
                                                <HeaderElement {...headerFormElStyle} className="search-header">
                                                    <HeaderForm />
                                                </HeaderElement>
                                            </HeaderRightInner>                                  
                                            <HeaderElement
                                                {...burgerBtnElStyle}
                                                visibility={{ default: 'false', lg: 'true' }}
                                            >
                                                <BurgerButton onClick={offCanvasHandler} />
                                            </HeaderElement>
                                            <HeaderElement
                                                {...clickAbleElStyle}
                                                visibility={{ default: 'false', sm: 'true' }}
                                            >
                                                <Clickable onClick={headerInnerHandler} {...clickAble}>
                                                    <FaSearch />
                                                </Clickable>
                                            </HeaderElement>
                                        </HeaderRight>
                                    </HeaderMain>
                                </Col>
                            </Row>
                        </Container>
                    </FixedHeader>
                    <FixedHeaderHeight height={fixedHeaderHeight} />
                </HeaderBottom>
            </HeaderWrap>
            <OffCanvas scrollable={true} isOpen={offCanvasOpen} onClick={offCanvasHandler}>
                <OffCanvasHeader onClick={offCanvasHandler}>
                    <Logo darkLogo align={{ default: 'flex-start' }} />
                </OffCanvasHeader>
                <OffCanvasBody>
                    <MobileMenu menuData={menuArr} newMenu={_menu} />
                </OffCanvasBody>
            </OffCanvas>
        </Fragment>
    )
}


Header.propTypes = {
    headerTop: PropTypes.object
}

Header.defaultProps = {
    headerTop: {
        textStyle: {
            fontSize: '14px',
            align: 'center',
            lineHeight: 1.78,
            pt: '10px',
            pb: '10px'
        },
        linkStyle: {
            fontWeight: 500
        }
    },
    headerBottom: {
        logoStyle: {
            align: {
                default: 'center',
                lg: 'center'
            }
        },
        langElStyle: {
            pr: '25px',
            responsive: {
                xlarge: {
                    pr: "10px"
                }
            }
        },
        headerFormElStyle: {
            responsive: {
                xsmall: {
                    mt: '0px'
                }
            }
        },
        burgerBtnElStyle: {
            pl: "20px"
        },
        clickAbleElStyle: {
            pl: "15px"
        },
        clickAble: {
            fontsize: "20px",
            color: "#6D70A6"
        }
    }
}

export default Header
